@use 'common'
@use 'sass:math'

.Gallery
	display: flex
	flex-wrap: wrap
	justify-content: center
	max-width: common.$container
	margin: auto
	padding: 2rem 1.5rem

	@media (min-width: common.$container)
		padding: 2rem 0


.ImageBox
	width: 50%
	position: relative

	&:nth-child(1):nth-last-child(odd)
		width: 100%

.Image
	position: relative
	overflow: hidden
	margin: 0.5rem
	cursor: pointer
	transition: 0.3s

	&::before
		display: block
		content: ""
		padding-top: 100% * math.div(241, 347)
	&:hover
		@media (min-width: 768px)
			transform: scale(1.05)

