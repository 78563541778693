@use 'common'

.FooterMenu
	background-color: red
	background-color: #333
	color: #fff
	padding: 0 10px 50px 10px
	position: relative
	z-index: 1

.Footer
	display: flex
	flex-direction: column
	max-width: 1200px
	margin: auto
	@media (min-width: 992px)
		flex-direction: row
		flex-wrap: wrap

.FooterBox
	padding: 1rem
	z-index: 2
	@media (min-width: 992px)
		width: 50%

.Container
	display: flex
	flex-direction: column
	@media (min-width: 768px)
		flex-direction: row

.LinkBoxes
	@media (min-width: 500px)
		display: flex

.LinkBox
	@media (min-width: 500px)
		width: 50%

.SocialIcons
	flex-direction: row

.Column
	@media (min-width: 768px)
		width: 50%

.Link
	font-family: common.$font-republic
	font-size: 20px
	line-height: 38px
	width: fit-content
	padding-right: 0.75rem

	&:hover
		opacity: 0.6

.Form
	display: flex
	flex-direction: column
	@media (min-width: 768px)
		flex-direction: row

.FormButton,
.FormInput
	border: 1px
	border-radius: 3px
	padding: 1rem

.FormInput
	font-size: 17px
	font-family: common.$font-republic
	@media (min-width: 992px)
		font-size: 20px

.FormButton
	margin: 1rem 0
	background-color: common.$mainColor
	color: #fff
	cursor: pointer
	opacity: 1
	font-size: 16px
	&:hover
		opacity: 0.8
	@media (min-width: 768px)
		margin: 0 1rem

.Title
	margin-bottom: 20px
	border-bottom: 2px solid common.$grey
	padding: 0 0 10px 0
	font-size: 14px
	font-weight: 700
	border-bottom: 1px solid
	color: #c0c0c0
	@media (min-width: 992px)
		padding: 30px 0 10px 0

.Copyright
	font-size: 10px
	color: common.$lightGrey
	padding: 0 1rem
	font-family: Arial, Helvetica, sans-serif
	z-index: 1
	a
		text-decoration: underline

.AppIcon
	border: 1px solid #fff
	border-radius: 5px
	margin: 0.5rem 0
	height: auto
	width: 180px
	opacity: 0.5
	&:hover
		opacity: 1

	@media (min-width: 768px)
		margin: 0.5rem

.SocialIcon
	margin: 0 20px 20px 0
	opacity: 1
	&:hover
		opacity: 0.8

.Logo
	display: none
	@media (min-width: 992px)
		position: absolute
		height: 100%
		top: 0
		left: 0
		right: 0
		display: flex
		justify-content: center
		align-items: center
		opacity: 0.25
