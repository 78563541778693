.Popup
	z-index: 10
	padding: .5em
	max-width: 80vw
	max-height: 80vh

	@media (max-width: 600px)
		position: static !important
		transform: none !important
		top: 0 !important
		left: 0 !important
		right: 0 !important
		bottom: 0 !important
