@use 'common'
@use 'sass:math'

.Wrapper
	@media (max-width: 600px)
		position: fixed
		top: 0
		left: 0
		right: 0
		bottom: 0
		padding: 1em
		z-index: 10
		display: flex
		align-items: center
		justify-content: center
		background: rgba(#000000, .5)

.Container
	position: relative
	background: white
	border-radius: .4em
	overflow: auto
	-webkit-overflow-scrolling: touch
	box-shadow: 0 0 2px rgba(#000000, .1), 0 0 6px rgba(#000000, .1), 0 0 16px rgba(#000000, .1)
	max-height: 100%

.Key
	display: none

.AnnotationImage
	position: relative
	width: 100%

.Text
	padding: 1em
	font-family: common.$font-republic

.Close
	position: absolute
	top: 10px
	right: 10px
	z-index: 2
	@media (min-width: 768px)
		display: none
