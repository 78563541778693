@use 'common'

.Content
	max-width: common.$container
	font-family: common.$font-republic
	margin: auto
	padding: 20px 0 90px
	font-family: common.$font-republic
	color: common.$darkGrey
	font-size: 18px
	line-height: 1.7
	@media (min-width: 768px)
		padding: 40px 0 90px

.ImageDesc
	font-size: 12px
	margin-top: 0

.Block
	padding: 0.75rem 1.5rem
	@media (min-width: 768px)
		padding: 1rem

	h1,
	h2,
	h3
		margin: 0

.Block.first p[data-contember-type="paragraph"]:first-child
	&::first-letter
		font-size: 100px
		float: left
		display: block
		line-height: 0.90
		padding-right: 0.75rem

.Block p[data-contember-type="paragraph"]
	margin: 0

.In
	max-width: common.$container
	margin: auto

.DefaultStyles
	font-family: common.$font-republic
	color: common.$darkGrey

	h1
		text-align: center

	ul
		margin-top: -2rem
		li
			margin: 1rem 1rem 1rem 0
			&::marker
				color: common.$mainColor
				font-size: 2rem

	p
		margin: 0
		font-size: 18px
		line-height: 1.7

	a
		text-decoration: underline
		color: common.$darkRed
		&:hover
			text-decoration: none

	ol
		li
			font-size: 20px
			line-height: 35px

	.Block:first-child > .In:first-child > p:first-child
		&::first-letter
			font-size: 100px
			float: left
			display: block
			line-height: 0.90
			padding-right: 0.75rem

.AlignLeft
	.In
		margin: 0 3rem
		@media (min-width: 1200px)
			margin: 0 6rem

.ViewCoverEditor
	padding: 0
	z-index: 2
	.Block
		padding: 0
		.In
			max-width: unset

	p
		margin: 0

.Quote
	position: relative
	max-width: common.$container
	margin: auto
	padding: 0 1.5rem
	@media (min-width: common.$container)
		padding: 0

.Text
	font-family: common.$font-republic
	font-size: 31px
	font-style: italic
	line-height: 53px
	color: common.$grey
	&::before
		content: ""
		color: common.$darkRed
		position: absolute
		left: -1em
		top: 0
		bottom: 0
		border-left: 1px solid
