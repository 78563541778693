@use 'common'
@use 'sass:math'

.ArticleLogo
	text-align: center
	padding: 15px 0

.Cover
	display: flex
	position: relative
	width: 100%

.Image
	position: relative
	width: 100%
	z-index: -1
	flex-shrink: 0

	&::before
		content: ""
		display: block
		padding-top: math.div(100%, math.div(1440, 500))
	&::after
		content: ""
		background-color: rgba(0, 0, 0, 0.4)
		position: absolute
		width: 100%
		height: 100%
		top: 0
		right: 0
		bottom: 0
		left: 0

.CoverContent
	width: 100%
	flex-shrink: 0
	align-self: center
	border-bottom: 1px solid #ddd
	padding: 40px 0
	&.WithImage
		margin-left: -100%
		border-bottom-color: transparent
		padding: 0

.TextBox
	display: flex
	flex-direction: column
	justify-content: center
	align-items: center
	max-width: 650px
	margin: auto
	text-align: center
	padding: 2rem 1rem

.LinkReferenceBox
	display: flex
	flex-wrap: wrap
	justify-content: center
	margin-bottom: 1rem

.LinkReference
	background-color: common.$darkRed
	padding: 0.5rem 1rem
	margin: 5px
	color: #fff
	border-radius: 25px
	transition: background 0.2s ease-in-out
	&:hover
		background-color: #000

.Title
	font-size: 70px
	margin: 0
	text-transform: uppercase
	font-weight: 400

.LeadParagraph
	font-size: 20px
	line-height: 25px
	font-family: common.$font-republic

.ImageSource
	color: #ffffffcf

.Title,
.LeadParagraph
	color: #3E3E3E

.CoverContent.WithImage
	.Title,
	.LeadParagraph
		color: #fff

.ImageSource
	position: absolute
	bottom: 0
	right: 0
	line-height: 21px
	margin: 1rem 2rem
	font-size: 12px

.InfoBox
	position: relative
	display: flex
	align-items: center
	max-width: common.$container
	margin: 2rem auto 1rem
	padding: 0 1.5rem
	@media (min-width: common.$container)
		padding: 0

.BackArrowInner
	display: none
	position: absolute
	top: 10px
	left: -200px
	background-color: #d0021b
	width: 48px
	height: 48px
	border-radius: 50%
	align-items: center
	justify-content: center
	box-shadow: 0 8px 14px rgba(0, 0, 0, 0.11)
	transition: background 0.2s ease-in-out
	@media (min-width: common.$container)
		display: flex
	svg
		transition: all 0.2s ease-in-out
	&:hover
		background-color: #000
		svg
			margin-left: -5px

.AuthorImage
	position: relative
	border-radius: 50%
	border: 1px solid #9f9f9f
	overflow: hidden
	height: 65px
	width: 65px

.AuthorName
	font-size: 12px
	letter-spacing: 1.26px
	text-transform: uppercase
	margin: 0.25rem 0

.CreatedAt
	font-size: 13px
	margin: 0.25rem 0
	color: #9f9f9f
	font-family: common.$font-republic

.Info
	margin-left: 19px
