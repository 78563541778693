@use 'common'
@use "sass:math"

.Cover
	position: relative

.Content
	max-width: 1200px
	margin: auto

.SectionsTitle
	text-transform: uppercase
	margin: 50px 0 0 16px
	font-size: 12px
	letter-spacing: 1.26px
	color: common.$lightGrey

.TimePeriodBox
	display: flex
	flex-wrap: wrap

.TimePeriodTile
	width: calc(100% - 2rem)
	position: relative
	min-height: 350px
	margin: 1rem
	@media (min-width: 500px)
		width: calc(100% / 2 - 2rem)
	@media (min-width: 992px)
		width: calc(100% / 3 - 2rem)
	@media (min-width: 1200px)
		width: calc(100% / 4 - 2rem)

.PersonalitiesBox
	display: flex
	flex-wrap: wrap
	margin: 1rem

.PersonalityTile
	margin: 1rem 1rem 1rem 0

.PersonalityMinor
	font-size: 9px
