$mainColor: #EF041D
$darkRed: #d0021b
$lightGrey: #a5a5a5
$darkGrey: #3E3E3E
$grey: #979797


$font-republic: republic, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif
$font-despekt: despekt, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif

$container: 882px
