@use 'common'
@use 'sass:math'

$lineHeight: 1.2em

.Cover
	background-color: #000

.CoverAndMenu
	position: relative
	display: flex
	justify-content: stretch
	width: 100%
	align-items: flex-start
	@media (min-width: 768px)
		align-items: center

.SideMenuBox
	position: relative
	width: 0
	pointer-events: none
	z-index: 2

.CoverBox
	display: flex
	flex-direction: column
	justify-content: center
	min-height: math.div(100vw, math.div(1440, 670))
	flex-grow: 1
	height: 70vh
	overflow: hidden

	@media (min-width: 768px)
		justify-content: space-between
		height: auto

	&:before
		content: ""
		display: flex
		height: 82px

.Title
	+common.title
	font-size: calc(15vw * var(--Cover-fontSize, 1))
	display: flex
	justify-content: center
	align-items: center
	z-index: 1
	text-transform: uppercase
	text-align: center
	padding: 0 2rem
	font-weight: 400
	@supports (-webkit-touch-callout: none)
		padding-right: 1rem

.Image
	position: absolute
	top: 0
	left: 0
	right: 0
	bottom: 0
	width: 100%
	height: 100%
	z-index: 0
	@media (min-width: 768px)
		padding-top: math.div(100%, math.div(1440, 670))

	&::after
		content: ""
		background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%)
		position: absolute
		width: 100%
		height: 100%
		top: 0
		right: 0
		bottom: 0
		left: 0

.MobileImage
	display: block
	@media (min-width: 768px)
		display: none

.ArrowBox
	display: none
	justify-content: center
	width: 100%
	@media (min-width: 768px)
		display: flex

.ArrowLink
	display: flex
	flex-direction: column
	align-items: center
	z-index: 1

.ArrowText
	font-size: 14px
	letter-spacing: 3.68px
	color: common.$lightGrey
	margin: 0
	font-family: common.$font-republic

.Arrow
	margin-top: -0.5rem

.LeadParagraphWrapper
	padding: 20px 0 90px
	max-width: common.$container
	margin: auto
	@media (min-width: 768px)
		padding: 40px 0 90px

.LeadParagraph
	+common.leadParagraph
	background-color: #000
	margin: 0
	display: flex
	justify-content: center
	margin-top: -3rem
	margin-bottom: 1.5rem
	font-size: 28px
	position: relative
	line-height: $lineHeight
	padding: 0 1rem
	@media (min-width: 768px)
		margin: 0 6rem
		font-size: 30px
	@media (min-width: 992px)
		font-size: 35px

	p
		margin: 0

	a
		color: common.$darkRed

.AnnotationBox
	max-width: 400px

.Key
	display: none

.AnnotationImage
	&::after
		background-image: none

.Boxes
	display: flex
	flex-direction: column
	position: absolute
	top: 0
	right: 0
	bottom: 0
	left: 0
	overflow: hidden
	z-index: 4
	pointer-events: none

	div
		$duration: 1s
		$gradientWidth: 1em
		width: calc(100% + $gradientWidth)
		margin-left: -$gradientWidth
		height: $lineHeight
		background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(0,0,0,1) $gradientWidth, rgba(0,0,0,1) 100%)
		animation: reveal $duration linear forwards
		flex-shrink: 0
		pointer-events: auto
		&:last-child
			flex-grow: 1

		//For loop number related to Cover.tsx

		@for $i from 2 through 20
			&:nth-child(#{$i})
				animation-delay: $duration * ($i - 1)

	@keyframes reveal
		0%
			transform: translateX(0)
		100%
			transform: translateX(100%)
