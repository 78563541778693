@use 'common'

.SideMenu
	z-index: 2

.Links
	display: none
	@media (min-width: 768px)
		display: block

.Mobile
	display: flex
	.Link
		display: none
	.isActive
		display: block
	@media (min-width: 768px)
		display: none

.Link
	display: block
	width: fit-content
	margin: 0.75rem 1rem 0.75rem 0
	padding: 0.25rem 0.5rem
	cursor: pointer
	color: #fff
	pointer-events: auto
	white-space: nowrap

	&.isActive
		background-color: common.$mainColor
		border-top-right-radius: 25px
		border-bottom-right-radius: 25px

.ActiveLink
	font-size: 23px

.MobileBtn
	display: flex
	align-items: center
	border: none
	background-color: common.$mainColor
	width: auto
	padding: 0
	pointer-events: auto
	margin-top: 1.25rem
	color: #fff
	font-size: 25px
	height: 40px
	padding-right: 0.75rem
	border-radius: 0 25px 25px 0
	font-family: common.$font-despekt
	white-space: nowrap
	margin-left: 0

.svgOpen,
.svgClose
	display: flex
	padding: 0.5rem

.svgClose,
.MenuClose
	display: none

.isOpen
	display: flex
	flex-direction: column-reverse
	width: 100vw
	background-color: #000
	position: fixed
	top: 0
	left: 0
	height: 100vh
	justify-content: flex-end

	.Links
		display: flex
		flex-direction: column
		align-items: center
		text-transform: uppercase

	.Link
		font-size: 1.75rem
		padding: 0
		&.isActive
			background-color: transparent
			color: common.$mainColor

	.svgOpen
		display: none

	.ActiveLink
		display: none

	.svgClose,
	.MenuClose
		display: flex
