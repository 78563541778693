@use 'common'

.TimePeriodTile
	.LeadParagraphBox
		opacity: 1
		@media (min-width: 768px)
			opacity: 0
	&:hover .LeadParagraphBox
		@media (min-width: 768px)
			transform: translateY(-15px)
			opacity: 1

	&::after, &::before
		display: block
		content: ""
		opacity: 1
		background-color: rgba(#000, 0.4)
		position: absolute
		top: 0
		right: 0
		left: 0
		bottom: 0
		width: 100%
		height: 100%

	@media (min-width: 768px)

		&:hover::before
			background-color: rgba(common.$mainColor, 0.4)
			opacity: 1
			z-index: 1
		&:hover .Title
			transform: translateY(-40px)

.Title
	position: absolute
	top: 0
	right: 0
	bottom: 0
	left: 0
	color: #fff
	z-index: 2
	display: flex
	justify-content: center
	align-items: center
	margin: 0
	font-size: 42px
	text-align: center
	letter-spacing: 0.6px
	text-transform: uppercase
	font-weight: 400
	// transition: background-color
	transition-duration: 0.7s

	@media (min-width: 768px)
		font-size: 42px

.LeadParagraphBox
	position: absolute
	width: 100%
	height: 100%
	opacity: 0
	display: flex
	flex-direction: column
	justify-content: flex-end
	align-items: center
	z-index: 2
	padding-bottom: 1rem
	transform: translateY(30px)
	transition-duration: 0.9s

.TileParagraph
	color: #fff
	text-align: center
	padding: 0 1rem
	font-family: common.$font-republic
	font-size: 14px

.Button
	border-radius: 5px
	background-color: #fff
	color: common.$darkRed
	padding: 8px 12px
	font-weight: 700
	font-size: 14px
	transition: 0.3s
	&:hover
		transform: scale(1.05)
